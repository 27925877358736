<template>
  <div class="navigation-title-section">
    <div class="navigation-title-section-content">
      Menus
    </div>
    <div class="new_menu_button_sec">
      <el-button @click="openMenuDialog">
        Add new menu
      </el-button>
    </div>
  </div>
  <div class="menu_select_section">
    <h4>Select menu: </h4>
    <el-select v-model="menuID" placeholder="Select">
      <el-option
        v-for="menu in menus"
        :key="menu.id"
        :label="menu.name"
        :value="menu.id">
        <p class="select_menu_p">
          <router-link :to="{
            name: 'menu-items',
            params: {
              id: menu.id
            }
          }">
            {{ menu.name }}
          </router-link>
        </p>
      </el-option>
    </el-select>
  </div>
  <div class="row">
    <router-view></router-view>
  </div>
  <div class="delete_menu_section" v-if="menuID">
    <el-button class="button_style_2" @click="confirmDelete = true">
      Delete menu
    </el-button>
  </div>
  <el-dialog
    title="Menu"
    v-model="dialogVisible"
    width="30%"
  >
    <el-input
      v-model="menuName"
      placeholder="Menu name"
      class="add_new_menu_input">
    </el-input>
    <el-button
      @click="addNewMenu()"
      class="button_style_1">
      ADD NEW MENU
    </el-button>
  </el-dialog>
  <el-dialog
    title="Confirm delete"
    v-model="confirmDelete"
    width="30%"
  >
    <p>Are you sure that you want to delete menu?</p>
    <el-button
      @click="deleteMenu(menuID)"
      class="button_style_2">
      DELETE MENU
    </el-button>
  </el-dialog>
</template>

<script>
import { getAllMenus, addNewMenu } from '../../services/menu'
import api from '../../services/api'

export default {
  name: 'Menu',
  data () {
    return {
      menus: [],
      menuID: null,
      menuName: null,
      dialogVisible: false,
      confirmDelete: false
    }
  },
  methods: {
    openMenuDialog () {
      this.dialogVisible = true
    },
    deleteMenu (id) {
      api.delete('api/menu/' + id).then(() => {
        this.menus.map((singleMenu, index) => {
          if (singleMenu.id === id) {
            this.menus.splice(index, 1)
          }
        })
        this.menuID = null
        this.$router.push('/menu')
        this.confirmDelete = false
      })
    },
    addNewMenu () {
      const loader = this.$loading.show()
      addNewMenu(this.menuName).then((results) => {
        this.menus.push(results.data)
        this.dialogVisible = false
        loader.hide()
        this.$notify({
          title: 'Success',
          message: 'Your menu is successfully added.',
          type: 'success'
        })
      }).catch((error) => {
        loader.hide()
        const objError = JSON.parse(error.request.response)
        let errorMessage
        if (objError.errors.name !== undefined) {
          errorMessage = objError.errors.name[0]
        } else {
          errorMessage = 'Server error! Please try later!'
        }
        this.$notify.error({
          title: 'Error',
          message: errorMessage,
          type: 'error'
        })
      })
    }
  },
  mounted () {
    const loader = this.$loading.show()
    getAllMenus().then(results => {
      this.menus = results
      loader.hide()
    })
  }
}
</script>
<style lang="scss" scoped>

.new_menu_button_sec {
  position: absolute;
  right: 15px;

  button {
    background-color: white;
    text-transform: uppercase;
    color: #42b983;
  }
}

.menu_select_section {
  width: 520px;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 50px;

  h4 {
    margin-bottom: 20px;
    font-size: 24px;
  }

  .el-select {
    width: 100%;

    .el-input {
      .el-input__inner {
        border-color: #42b983;
      }
    }
  }
}

.add_new_menu_input {
  margin-bottom: 20px;
}

.select_menu_p a {
  display: block;
  width: 100%;
  color: #42b983;
  text-decoration: none;
}

.delete_menu_section {
  position: absolute;
  right: 15px;
  botton: 15px;
}
</style>
