<template>
  <div>
    <template v-for="(item, index, index2) in items">
      <div class="divider" v-if="item.type === 'divider'" :key="index"/>
      <menu-item v-else :key="index2" v-bind="item"/>
    </template>
    <file-selector ref="fileSelect"
                   :mediaType="mediaType"
                   :fileSelect="fileSelect"
                   :showButton="false"/>
    <el-dialog
      v-model="link_popup"
      width="30%"
      :before-close="handleClose">
      <h3 class="categories_popup__h3">Insert url </h3>
      <el-input
        v-model="text_link"
        label="Link"
      ></el-input>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">Cancel</el-button>
          <el-button @click="setLinkUrl(); dialogVisible = false" class="button_style_1">Insert link</el-button>
        </span>
      </template>

    </el-dialog>
  </div>
</template>

<script>
import MenuItem from './MenuItem.vue'
import FileSelector from './FileSelector'

export default {
  components: {
    MenuItem,
    FileSelector
  },
  props: {
    editor: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      link_popup: false,
      link_url: '',
      text_link: '',
      mediaType: 'image',
      items: [
        {
          icon: 'paragraph',
          title: 'Paragraph',
          action: () => this.editor.chain().focus().setParagraph().run(),
          isActive: () => this.editor.isActive('paragraph')
        },
        {
          icon: 'h-1',
          title: 'Heading 1',
          action: () => this.editor.chain().focus().toggleHeading({ level: 1 }).run(),
          isActive: () => this.editor.isActive('heading', { level: 1 })
        },
        {
          icon: 'h-2',
          title: 'Heading 2',
          action: () => this.editor.chain().focus().toggleHeading({ level: 2 }).run(),
          isActive: () => this.editor.isActive('heading', { level: 2 })
        },
        {
          icon: 'h-3',
          title: 'Heading 3',
          action: () => this.editor.chain().focus().toggleHeading({ level: 3 }).run(),
          isActive: () => this.editor.isActive('heading', { level: 3 })
        },
        {
          icon: 'h-4',
          title: 'Heading 4',
          action: () => this.editor.chain().focus().toggleHeading({ level: 4 }).run(),
          isActive: () => this.editor.isActive('heading', { level: 4 })
        },
        {
          icon: 'list-unordered',
          title: 'Bullet List',
          action: () => this.editor.chain().focus().toggleBulletList().run(),
          isActive: () => this.editor.isActive('bulletList')
        },
        {
          icon: 'list-ordered',
          title: 'Ordered List',
          action: () => this.editor.chain().focus().toggleOrderedList().run(),
          isActive: () => this.editor.isActive('orderedList')
        },
        {
          type: 'divider'
        },
        {
          icon: 'bold',
          title: 'Bold',
          action: () => this.editor.chain().focus().toggleBold().run(),
          isActive: () => this.editor.isActive('bold')
        },
        {
          icon: 'italic',
          title: 'Italic',
          action: () => this.editor.chain().focus().toggleItalic().run(),
          isActive: () => this.editor.isActive('italic')
        },
        {
          icon: 'strikethrough',
          title: 'Strike',
          action: () => this.editor.chain().focus().toggleStrike().run(),
          isActive: () => this.editor.isActive('strike')
        },
        {
          icon: 'code-view',
          title: 'Code',
          action: () => this.editor.chain().focus().toggleCode().run(),
          isActive: () => this.editor.isActive('code')
        },
        {
          type: 'divider'
        },
        {
          icon: 'image-2-fill',
          title: 'Image',
          action: () => this.openModal('image')
        },
        // {
        //   icon: 'gallery-line',
        //   title: 'Gallery',
        //   action: () => this.openModal('gallery')
        // },
        {
          icon: 'file-copy-2-fill',
          title: 'Documents',
          action: () => this.openModal('document')
        },
        {
          icon: 'link-m',
          title: 'Insert Link',
          action: () => this.openLinkPopup()
        },
        {
          icon: 'link-unlink-m',
          title: 'Unlink',
          action: () => this.editor.chain().focus().unsetLink().run()
        },
        {
          type: 'divider'
        },
        {
          icon: 'align-left',
          title: 'Align left',
          action: () => this.editor.chain().focus().setTextAlign('left').run(),
          isActive: () => this.editor.isActive({ textAlign: 'left' })
        },
        {
          icon: 'align-center',
          title: 'Align center',
          action: () => this.editor.chain().focus().setTextAlign('center').run(),
          isActive: () => this.editor.isActive({ textAlign: 'center' })
        },
        {
          icon: 'align-right',
          title: 'Align right',
          action: () => this.editor.chain().focus().setTextAlign('right').run(),
          isActive: () => this.editor.isActive({ textAlign: 'right' })
        },
        {
          type: 'divider'
        },
        {
          icon: 'text-wrap',
          title: 'Hard Break',
          action: () => this.editor.chain().focus().setHardBreak().run()
        },
        {
          type: 'divider'
        },
        {
          icon: 'arrow-go-back-line',
          title: 'Undo',
          action: () => this.editor.chain().focus().undo().run()
        },
        {
          icon: 'arrow-go-forward-line',
          title: 'Redo',
          action: () => this.editor.chain().focus().redo().run()
        },
        {
          icon: 'arrow-go-forward-line',
          title: 'Redo 2',
          action: () => {
            console.log('this.editor.chain().focus()', this.editor.chain().focus())
            return this.editor.chain().focus().setImage({ src: 'https://i.ytimg.com/vi/YeQFBS6IDAQ/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCQf0K3-40WhbzLQri3Uvvet_aHjg' }).run()
          }
        }
      ]
    }
  },
  methods: {

    fileSelect (file) { // { i
      if (file.media === 'image') {
        this.editor.chain().focus().setImage({ src: process.env.VUE_APP_SHARED_API + file.properties.medium }).run()
      } else if (file.type === 'document') {
        this.editor.chain().focus().insertContent('<a href="' + file.src + '" target="_blank">' + file.name + '</a>').run()
      } else {

      }
    },

    openLinkPopup () {
      this.link_popup = true
    },

    setLinkUrl () {
      this.link_url = this.text_link

      var url = this.link_url

      this.editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url })
        .run()

      this.link_popup = false
    },

    openModal (mediaType) {
      this.mediaType = mediaType
      this.$refs.fileSelect.dialog = true
    },

    handleClose (done) {
      this.$confirm('Are you sure to close this dialog?')
        .then(_ => {
          done()
          this.dialogVisible = false
        })
        .catch(_ => {
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.divider {
  width: 2px;
  height: 1.25rem;
  margin-left: 0.5rem;
  margin-right: 0.75rem;
}

.v-application .primary--text {
  color: #42b983 !important;
  caret-color: #42b983 !important;
}

.editor__header {
  display: flex;
  margin: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid gray;
  justify-content: space-between;
  overflow-x: scroll;
  .menu-item {
    flex: 0 0 1.75rem;
  }
}

.v-dialog > .v-card > .v-card__text.link_section_popup {
  padding: 25px;
}
</style>
