import api from '../../services/api'

export default {
  state: {
    images: null,
    documents: null,
    audio: null
  },
  mutations: {
    SET_IMAGES_DATA (state, images) {
      state.images = images
    },
    SET_DOCUMENTS_DATA (state, documents) {
      state.documents = documents
    },
    SET_AUDIO_DATA (state, audio) {
      state.audio = audio
    },
    DELETE_SINGLE_MEDIA (state, id) {
      state.images.map((singleMedia, index) => {
        if (singleMedia.id === id) {
          state.images.splice(index, 1)
        }
      })
      state.documents.map((singleMedia, index) => {
        if (singleMedia.id === id) {
          state.documents.splice(index, 1)
        }
      })
      state.audio.map((singleMedia, index) => {
        if (singleMedia.id === id) {
          state.audio.splice(index, 1)
        }
      })
    }
  },
  actions: {
    setMediaData ({ commit }, type) {
      return api.get('api/media?per_page=500&type=' + type)
        .then((response) => {
          if (type === 'image') {
            commit('SET_IMAGES_DATA', response.data.data.map(singleImage => {
              singleImage.src = process.env.VUE_APP_SHARED_API + singleImage.properties.medium
              singleImage.media = 'image'
              return singleImage
            }))
          } else if (type === 'documents') {
            commit('SET_DOCUMENTS_DATA', response.data.data.map(singleDoc => {
              singleDoc.src = process.env.VUE_APP_SHARED_API + singleDoc.slug
              singleDoc.media = 'document'
              return singleDoc
            }))
          } else if (type === 'audio') {
            commit('SET_AUDIO_DATA', response.data.data.map(singleAudio => {
              singleAudio.src = process.env.VUE_APP_SHARED_API + singleAudio.slug
              singleAudio.media = 'audio'
              return singleAudio
            }))
          }
        })
    },
    deleteMedia ({ commit }, id) {
      return api.delete('api/media/' + id)
        .then((response) => {
          commit('DELETE_SINGLE_MEDIA', id)
        })
    }
  }
}
