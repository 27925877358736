<template>
  <div class="container">
    <div class="row">
      <div class="col-md-5">
      </div>
      <div class="col-md-5">
        <el-input placeholder="Please input" v-model="input"></el-input>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Register',
  data () {
    return {
      input: ''
    }
  }
}
</script>
