<template>
  <div class="audio_selector_input">
    <div v-if="multiple" class="preview-single">
      <img v-if="audioArr[0]" :src="audioArr[0].src" alt=""/>
    </div>
    <div v-else>
      <div v-if="mutableAudioSlug !== 'empty'">
        <audio controls>
          <source :src="mutableAudioSlug" type="audio/ogg">
          <source :src="mutableAudioSlug" type="audio/mpeg">
          Your browser does not support the audio element.
        </audio>
      </div>
      <div v-else-if="featuredAudioSlugData !== 'empty'">
        <audio controls>
          <source :src="featuredAudioSlugData" type="audio/ogg">
          <source :src="featuredAudioSlugData" type="audio/mpeg">
          Your browser does not support the audio element.
        </audio>
      </div>
    </div>
    <file-selector :fileSelect="fileSelect" :mediaType='audio' class="file_select"/>
    <el-button
      @click="removeFeaturedAudio"
      class="button_style_2"
      v-if="this.mutableAudioSlug !== 'empty'">
      Remove audio file
    </el-button>
  </div>
</template>

<script>
import FileSelector from './FileSelector'

export default {
  name: 'ImageSelectorInput',
  components: { FileSelector },
  data () {
    return {
      audioArr: [],
      audio: 'audio',
      mutableAudioSlug: '',
      featuredAudioSlugData: this.featuredAudioSlug
    }
  },
  props: {
    multiple: Boolean,
    featuredAudioSlug: String
  },
  methods: {
    fileSelect (file) {
      this.audioArr = [file]
      this.mutableAudioSlug = file.src
      this.$emit('update:modelValue', file.id)
    },
    removeFeaturedAudio () {
      this.mutableAudioSlug = 'empty'
      this.featuredAudioSlugData = 'empty'
      this.$emit('update:modelValue', 0)
    }
  },
  mounted () {
    this.mutableAudioSlug = this.featuredAudioSlug
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.file_select {
  display: inline-block;
}

.audio_selector_input .button_style_2{
  margin-left: 15px;
}
</style>
